.portada {
  min-height: 510px;
  width: auto;
  padding: 0% 5% 0px 5%;
  background-color: #ffd43f;
}
.portada img {
  min-height: 500px;
  width: 100%;
  mix-blend-mode: darken;
  object-fit: cover;
}
