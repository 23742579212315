body {
  background-color: rgb(255, 255, 255);
  padding: 16px;
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
}
h1 {
  font-size: 2.2em;
  color: rgb(0, 0, 0);
  text-align: center;
  font-weight: 900;
}
p {
  text-align: center;
  font-size: medium;
}
.App {
  background-color: #ffd43f;
}
.Subportada {
  margin-bottom: 20px;
}
.Subportada h1 {
  font-size: 1.4rem;
  color: #333;
  font-family: "Playfair Display", Sans-serif;
  font-weight: 300;
  letter-spacing: -0.9px;
}
.Subportada p {
  font-size: 0.9rem;
  font-weight: 100;
  line-height: 0.2;
}
.about {
  display: flexbox;
  padding: 2% 20%;
  justify-content: center;
}
.about h1 {
  margin-bottom: 50px;
}
.about h2 {
  font-size: medium;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  color: #222;
}
.about p {
  text-align: center;
  font-size: medium;
  font-size: 1em;
  line-height: 1.5;
  margin: 5px 0px 40px 0px;
  text-align: justify;
  text-justify: inter-word;
  color: #333;
}

.VideoList {
  padding: 2% 20% 2% 20%;
}
.VideoListgrid {
  display: grid;
  gap: 1.2rem;
  grid-auto-flow: dense;
  grid-auto-rows: 12rems;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 25rem), 1fr));
}

.VideoListgrid iframe {
  border: none;
  width: 100%;
  height: 380px;
  background-color: #000000;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
}

.VideoListgrid .videoitem {
  grid-column: span 1;
  object-fit: 16 / 9;
}

.video43 {
  background-color: #7c2db8;
}

/*DESKTOP*/
@media (min-width: 769px) and (max-width: 1500px) {
  .about {
    padding: 1% 10%;
  }
  .VideoList {
    padding: 1% 10% 1% 10%;
  }

  .VideoListgrid iframe {
    border: none;
    width: 100%;
    height: 350px;
    background-color: #000000;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
  }

  .VideoListgrid .videoitem {
    grid-column: span 1;
    object-fit: 16 / 9;
  }
}

/*TABLET*/
@media (min-width: 451px) and (max-width: 768px) {
  .about {
    padding: 1% 5%;
  }
  .VideoList {
    padding: 5%;
  }
  .VideoListgrid iframe {
    border: none;
    width: 100%;
    height: 330px;
    background-color: #000000;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
  }
}
/*MOBILE*/
@media (max-width: 450px) {
  .portada {
    min-height: 530px;
    width: auto;
    padding: 0% 5% 16px 5%;
  }
  .about {
    padding: 1% 3%;
  }
  .VideoList {
    padding: 2% 10%;
  }
}
