.contact {
  width: auto;
  padding: 1% 5% 1px 5%;
  text-align: center;
}
.contact h2 {
  font-size: 1.2em;
  font-weight: 500;
}
.iconenvelope {
  text-align: center;
  padding: 10px;
}
.iconenvelope img {
  text-align: center;
  width: 32px;
  height: auto;
}
.socialbar {
  padding: 1% 20%;
  margin-bottom: 50px;
}
.socialbar div {
  display: inline-block;
  padding: 10px;
}
.socialbar div:hover {
  opacity: 0.5;
  cursor: pointer;
}
.socialbar div a {
  color: rgb(0, 0, 0);
}
